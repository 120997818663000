<template>
  <div>
    <div class="d-md-flex mb-3">
      <div :class="isMobile ? 'w-100' : 'mr-3'">
        <a-radio-group :class="isMobile ? 'w-100' : null" @change="handleChangeType" size="large" v-model="type" button-style="solid">
          <a-radio-button value="mid">
            MID
          </a-radio-button>
          <a-radio-button value="final">
            FINAL
          </a-radio-button>
        </a-radio-group>
      </div>
      <div class="ml-auto">
        <a-button
          :block="isMobile"
          :class="isMobile ? 'my-3' : null"
          size="large"
          @click.prevent="refreshTable"
        >
          <a-icon type="sync" :spin="loadingRefresh" />
          Refresh
        </a-button>
      </div>
    </div>
    <div>
      <a-table :columns="parentColumns" :data-source="data" class="components-table-demo-nested hasan-table-overflow" bordered :loading="loadingTable" @change="handleTableChange" :pagination="pagination">
        <a-table slot="expandedRowRender" slot-scope="value" :columns="childreColumns" :data-source="value.classData" :pagination="false">
          <div slot="knowledgeProgress" slot-scope="value">
            <a-progress style="width: 70%" :percent="value"/>
          </div>
          <div slot="skillProgress" slot-scope="value">
            <a-progress style="width: 70%" :percent="value"/>
          </div>
        </a-table>
      </a-table>
    </div>
  </div>
</template>

<script>
const parentColumns = [
  {
    title: 'Teacher Name',
    dataIndex: 'teacherName',
    scopedSlots: { customRender: 'teacherName' },
    width: 215,
    align: 'center',
  },
  {
    title: 'Subject Name',
    dataIndex: 'subjectName',
    scopedSlots: { customRender: 'subjectName' },
    width: 200,
    align: 'center',
  },
  {
    title: 'Total Class Being Teached',
    dataIndex: 'totalClass',
    scopedSlots: { customRender: 'totalClass' },
    width: 200,
    align: 'center',
  },
]

const childreColumns = [
  {
    title: 'Class Name',
    dataIndex: 'className',
    scopedSlots: { customRender: 'className' },
    width: '20%',
    align: 'center',
  },
  {
    title: 'Total KD',
    dataIndex: 'totalKD',
    scopedSlots: { customRender: 'totalKD' },
    width: '20%',
    align: 'center',
  },
  {
    title: 'Knowledge Progress',
    dataIndex: 'knowledgeProgress',
    scopedSlots: { customRender: 'knowledgeProgress' },
    width: '30%',
    align: 'center',
  },
  {
    title: 'Skill Progress',
    dataIndex: 'skillProgress',
    scopedSlots: { customRender: 'skillProgress' },
    width: '30%',
    align: 'center',
  },
]

export default {
  data() {
    return {
      parentColumns,
      childreColumns,
      data: [],
      pagination: {},
      loadingTable: false,
      loadingRefresh: false,
      type: 'mid',
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetchDataTable({
        page: pagination.current,
        type: this.type,
      })
    },
    handleChangeType() {
      this.fetchDataTable({
        page: this.pagination.current,
        type: this.type,
      })
    },
    refreshTable() {
      this.loadingRefresh = true
      this.fetchDataTable({
        page: this.pagination.current,
        type: this.type,
      })
    },
    fetchDataTable(params = { page: 'all', type: this.type }) {
      // console.log('type :>> ', type)
      this.loadingTable = true
      this.$store.dispatch('curriculum/FETCH_SUBJECT_TEACHERS_PROGRESS', { page: params.page, type: params.type, idGuru: this.user.id })
        .then(res => {
          this.loadingTable = false
          this.loadingRefresh = false
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          // console.log('res :>> ', res)
          this.data = res.data.map(row => {
            const dataKelas = row.kelas
            const totalClass = dataKelas.length || ''
            const classData = []
            for (let i = 0; i < dataKelas.length; i++) {
              const key = dataKelas[i].id || ''
              const className = `${dataKelas[i].tingkat}-${dataKelas[i].simbol}` || ''
              const knowledgeProgress = (dataKelas[i].progress.knowledge) * 100
              const skillProgress = (dataKelas[i].progress.skill) * 100
              const totalKD = dataKelas[i].total_kd || 0
              const objClass = {
                key,
                className,
                knowledgeProgress,
                skillProgress,
                totalKD,
              }
              classData.push(objClass)
            }
            return {
              key: row.guru.id,
              teacherName: row.guru.nama || '',
              subjectName: row.mapel.nama || '',
              totalClass,
              classData,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataTable({
      page: this.pagination.current,
      type: this.type,
    })
  },
  computed: {
    user() { return this.$store.state.user.user },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style scoped>
</style>
